<template>
    <page-content>
        <!-- <template v-slot:summary>
            Services fusce elementum libero at tortor vulputate facilisis. Morbi nec viverra massa.
        </template> -->
        
        <template v-slot:watermark>
            <contact-icon />
        </template>

        <p>Send us your questions or comments.  We'll get back to you within 24 hours.</p>

        <div>
            <a href="mailto:info@lucidqs.com">info@lucidqs.com</a>
        </div>
        <!-- <div id="form" v-if="!submitted">
            <div><label for="name">Name</label><input type="text" v-model="messageData.name"  /></div>
            <div><label for="name">Email</label><input type="email" v-model="messageData.email" /></div>
            <div><label for="message">Message</label><textarea v-model="messageData.message"></textarea></div>

            <button @click="submitForm">
                <span>Send</span>
            </button>
        </div>
        <div v-else>
            Thanks!
        </div> -->

        
    </page-content>
</template>

<script>
    import { post } from 'axios';
    import Content from '@/components/Content';
    import ContactIcon from '@/assets/contact.svg';
    import Pageclip from 'pageclip';

    const key = 'api_T7DBJIFREm21MNfBu0J1ftMGwAv0ShxV';
    const pageclip = new Pageclip(key);


    export default {
        components: {
            'page-content': Content,
            'contact-icon': ContactIcon
        },

        data() {
            return {
                messageData: {
                    name: "",
                    email: "",
                    message: ""
                },
                submitted: false
            }
        },
        
        methods: {
            async submitForm() {
                const response = await pageclip.send(this.messageData);
                console.log(response);

                this.submitted = true;

            }
        }
    }
</script>

<style scoped lang="scss">
    #wordmark {
        margin-bottom: 0;
    }
    #form {
        margin-top: 3em;

        div {
            margin-bottom: 1.5em;

            label {
                display: block;
            }

            input, textarea {
                width: 95%;
                border: 1px solid white;
                padding: 0.5em;
                font-size: 1em;
                border-radius: 4px;
            }

            textarea {
                height: 140px;
            }
        }

        button {
            width: 160px;
            height: 30px;
            font-size: 1em;
            border: 1px solid white;
            border-radius: 4px;
            background-color: color('primary');
        }
    }

    .content {
        min-height: 600px;

        @include device('desktop'){
            min-height: 800px;
        }
    }
</style>
