<template>
    <div class="content">
        <div class="summary">
            <slot name="summary"/>
        </div>
        <div class="body">
            <slot/>
        </div>
        <div class="watermark">
            <slot name="watermark"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'page-content'
    }
</script>

<style scoped lang="scss">
    .content{
        position: relative;
        color: color('primary');

        .summary {
            position: relative;
            font-size: 1.25em;
            margin-top: 3em;
            margin-bottom: 2em;
            z-index: 10;

            @include device('desktop'){
                font-size: 1.5em;
            }
        }

        .body {
            position: relative;
            line-height: 1.5em;
            z-index: 10;
        }

        .watermark {
            position: absolute;
            top: 180px;
            left: -20px;
            width: 100%;
            z-index: 1;

            opacity: 5%;
            -webkit-opacity: 0.05;
            svg{
                fill: white;
            }
        }
    }
</style>